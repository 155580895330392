import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifyForgotPassword, AmplifyGreetings } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Link, Route, Switch } from "react-router-dom";
import awsconfig from '../aws-exports';
import coursedesc from '../coursedescription.json';
import Typography from '@material-ui/core/Typography';
import AuthHeader from "./AuthHeader";
import BagImage from "../images/paper-bag-strip.png";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
      height: 300,
      width: 250,
      display: "flex",
      justifyContent: "center",
      padding: 20,
      background: "#ffffff",
      borderRadius: 20, 
      borderColor: "#f9a256", 
      borderWidth: '15px',
    },
    paperheading: {
        justifyContent: "center",
        padding: 30,
        marginLeft: "20%",
        marginRight: "20%",
        backgroundImage: `url(${BagImage})`
    },
    gridContainer: {
        marginLeft: "15%",
        marginRight: "15%",
    },
    control: {
      padding: 2,
    },
    backgroundStyle: {
        backgroundImage: `url(${BagImage})`
    }
  });

class CourseOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;
        return (
          <div className='Login-component'>
            <AuthHeader gobackvisibility={"hidden"} signoutvisibility={"visible"} user={this.props.user} />
            <div>
                <center>
                    <img height={250} src={process.env.PUBLIC_URL + "/KindergartenREADY_lockup_nobg_1200x630.png"}/>
                </center>
                {Object.keys(coursedesc).map((key) => (
                    <div>
                        <Typography style={{ fontFamily: "LuloCleanOneBold", fontSize: "10pt", color: "#000000" }}><br/></Typography>
                        <center>
                            <Typography style={{ fontFamily: "LuloCleanOneBold", fontSize: "22pt", color: "#4d2c58" }}>
                                {coursedesc[key]["label"]}
                            </Typography>
                        </center>
                        <br/><br/>

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item className={classes.gridContainer} xs={12}>
                                <Grid container justify="center" spacing={2}>
                                    {Object.keys(coursedesc[key]["items"]).map((item) => (
                                    <Grid key={key} item>
                                        <Link style={{textDecoration: "none"}} to={"/coursesession/" + key + "/" + item}>
                                            <Paper className={classes.paper} variant="outlined" square>
                                                <center>
                                                <Typography style={{ fontFamily: "LuloCleanOneBold", fontSize: "28pt", color: "#4d2c58" }}>
                                                    <div style={{fontSize: "8pt"}}><br/></div>
                                                    <div style={{fontSize: "17pt"}}>
                                                        {coursedesc[key]["items"][item]["label"]}
                                                    </div>
                                                </Typography>
                                                <Typography style={{ fontFamily: "LibreBaskerville", fontSize: "17pt", color: "#4d2c58" }}>
                                                    <div style={{fontSize: "14pt"}}><br/></div>
                                                    <img width={100} height={100} src={coursedesc[key]["items"][item]["icon"]} />
                                                    <div style={{fontSize: "8pt"}}><br/></div>
                                                    <div style={{fontSize: "18pt"}}>
                                                        <i><b>{coursedesc[key]["items"][item]["academic_label"]}</b></i><br/>
                                                    </div>
                                                    <div style={{fontSize: "12pt"}}>
                                                        {coursedesc[key]["items"][item]["activity_label"]}
                                                    </div>
                                                </Typography>
                                                </center>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <br/><br/>
                    </div>
            ))}

            <br/><br/>
            <center>
                <Typography style={{ fontFamily: "Avenir-Medium", fontSize: "10pt", color: "#000000" }}>
                    Icons made by <Link to="https://www.flaticon.com/authors/smashicons">Smashicons</Link> at <Link to="https://www.flaticon.com">www.flaticon.com</Link>            
                </Typography>   
            </center>
        </div>
      </div>
      )
  }
}

export default withStyles(styles)(CourseOverview);