import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifyForgotPassword, AmplifyGreetings } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Link, Route, Switch } from "react-router-dom";
import awsconfig from '../aws-exports';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import coursedesc from '../coursedescription.json';
import AuthHeader from "./AuthHeader";
import { Document, Page, pdfjs, Outline } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    paperheading: {
        justifyContent: "center",
        padding: 15,
        marginLeft: "20%",
        marginRight: "20%",
        background: "#f37658"
    },
    paper: {
        marginLeft: "10%",
        marginRight: "10%",
        justifyContent: "center",
        padding: 40,
        background: "#ffffff",
        borderRadius: 20, 
        borderColor: "#f9a256", 
        borderWidth: '10px',
      },
  });

class CourseSession extends Component {
    letterArray = {
        "letterA"  : {
            "label" : "Day 1: Letter A",
            "preacademics": {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/b3084faa-be3a-4d27-b98b-2f5f24abfb97/dash/SUSAN+BLT+LETTER+A.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/b3084faa-be3a-4d27-b98b-2f5f24abfb97/hls/SUSAN+BLT+LETTER+A.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/b3084faa-be3a-4d27-b98b-2f5f24abfb97/mp4/SUSAN+BLT+LETTER+A_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/44a73330-f750-4192-9748-7f820a7b8acb/dash/MEILANI+BLT+LETTER+A.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/44a73330-f750-4192-9748-7f820a7b8acb/hls/MEILANI+BLT+LETTER+A.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/44a73330-f750-4192-9748-7f820a7b8acb/mp4/MEILANI+BLT+LETTER+A_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterA.pdf"
            }
        },
        "letterB"  : {
            "label" : "Day 2: Letter B",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/779ea767-796e-4bc4-8a7a-50bae330eb46/dash/BLT+SUSAN+LETTER+B.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/779ea767-796e-4bc4-8a7a-50bae330eb46/hls/BLT+SUSAN+LETTER+B.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/779ea767-796e-4bc4-8a7a-50bae330eb46/mp4/BLT+SUSAN+LETTER+B_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9cb9c074-ecfa-4f1e-9744-5c0b5a4c83d5/dash/MEILANI+BLT+LETTER+B.mov.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9cb9c074-ecfa-4f1e-9744-5c0b5a4c83d5/hls/MEILANI+BLT+LETTER+B.mov.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9cb9c074-ecfa-4f1e-9744-5c0b5a4c83d5/mp4/MEILANI+BLT+LETTER+B.mov_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterB.pdf"
            }
        },
        "letterC"  : {
            "label" : "Day 3: Letter C",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/14067c41-b9f9-4d55-8949-adc39d278926/dash/BLT+LETTER+C+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/14067c41-b9f9-4d55-8949-adc39d278926/hls/BLT+LETTER+C+SUSAN_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_6.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/14067c41-b9f9-4d55-8949-adc39d278926/mp4/BLT+LETTER+C+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e4f1b607-c2ab-47df-bb15-7d88c7f4ac80/dash/MEILANI+BLT+LETTER+C.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e4f1b607-c2ab-47df-bb15-7d88c7f4ac80/hls/MEILANI+BLT+LETTER+C_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e4f1b607-c2ab-47df-bb15-7d88c7f4ac80/mp4/MEILANI+BLT+LETTER+C_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterC.pdf"
            }
        },
        "letterD"  : {
            "label" : "Day 4: Letter D",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e7952ce9-60c6-4012-8043-1225edba3fcd/dash/BLT+LETTER+D+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e7952ce9-60c6-4012-8043-1225edba3fcd/hls/BLT+LETTER+D+SUSAN_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e7952ce9-60c6-4012-8043-1225edba3fcd/mp4/BLT+LETTER+D+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9fc80d19-7b45-42e5-84f7-caeca576935a/dash/MEILANI+BLT+LETTER+D.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9fc80d19-7b45-42e5-84f7-caeca576935a/hls/MEILANI+BLT+LETTER+D.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/9fc80d19-7b45-42e5-84f7-caeca576935a/mp4/MEILANI+BLT+LETTER+D_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterD.pdf"
            }
        },
        "letterE"  : {
            "label" : "Day 5: Letter E",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/dash/BLT+LETTERS+E+AND+F+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/hls/BLT+LETTERS+E+AND+F+SUSAN.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/mp4/BLT+LETTERS+E+AND+F+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/ba9fb9ca-19fd-466e-bdaa-d455cfa07739/dash/MEILANI+BLT+LETTER+E.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/ba9fb9ca-19fd-466e-bdaa-d455cfa07739/hls/MEILANI+BLT+LETTER+E_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/ba9fb9ca-19fd-466e-bdaa-d455cfa07739/mp4/MEILANI+BLT+LETTER+E_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterE.pdf"
            }
        },
        "letterF"  : {
            "label" : "Day 6: Letter F",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/dash/BLT+LETTERS+E+AND+F+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/hls/BLT+LETTERS+E+AND+F+SUSAN.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7c8733b-a07e-4e74-a309-a1befa8023fd/mp4/BLT+LETTERS+E+AND+F+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f8455cde-331b-4044-9a5b-baf301c0fbf7/dash/MEILANI+BLT+LETTER+F.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f8455cde-331b-4044-9a5b-baf301c0fbf7/hls/MEILANI+BLT+LETTER+F.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f8455cde-331b-4044-9a5b-baf301c0fbf7/mp4/MEILANI+BLT+LETTER+F_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterF.pdf"
            }
        },
        "letterG"  : {
            "label" : "Day 7: Letter G",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/80599442-ef03-4fac-9167-ef80943a3f42/dash/BLT+SUSAN+LETTER+G.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/80599442-ef03-4fac-9167-ef80943a3f42/hls/BLT+SUSAN+LETTER+G.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/80599442-ef03-4fac-9167-ef80943a3f42/mp4/BLT+SUSAN+LETTER+G_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/878eb161-19a5-4a03-bd28-ad0a45f4cdb8/dash/MEILANI+BLT+LETTER+G.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/878eb161-19a5-4a03-bd28-ad0a45f4cdb8/hls/MEILANI+BLT+LETTER+G_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/878eb161-19a5-4a03-bd28-ad0a45f4cdb8/mp4/MEILANI+BLT+LETTER+G_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterG.pdf"
            }
        },
        "letterH"  : {
            "label" : "Day 8: Letter H",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/46cc31af-5996-43bb-8268-a7c1c761c966/dash/BLT+SUSAN+LETTER+H.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/46cc31af-5996-43bb-8268-a7c1c761c966/hls/BLT+SUSAN+LETTER+H.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/46cc31af-5996-43bb-8268-a7c1c761c966/mp4/BLT+SUSAN+LETTER+H_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7e4cd60-16e5-42e8-84f7-06a14821e003/dash/MEILANI+BLT+LETTER+H.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7e4cd60-16e5-42e8-84f7-06a14821e003/hls/MEILANI+BLT+LETTER+H_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/d7e4cd60-16e5-42e8-84f7-06a14821e003/mp4/MEILANI+BLT+LETTER+H_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterH.pdf"
            }
        },
        "letterIJ"  : {
            "label" : "Day 9: Letters I and J",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/dcaba452-8f2b-4446-af85-0ba3ad54329f/dash/BLT+SUSAN+LETTER+I+AND+J.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/dcaba452-8f2b-4446-af85-0ba3ad54329f/hls/BLT+SUSAN+LETTER+I+AND+J_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/dcaba452-8f2b-4446-af85-0ba3ad54329f/mp4/BLT+SUSAN+LETTER+I+AND+J_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c9734706-f6c3-482a-b449-0d65e4b1e1ea/dash/BLT+LETTER+I+AND+J.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c9734706-f6c3-482a-b449-0d65e4b1e1ea/hls/BLT+LETTER+I+AND+J_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c9734706-f6c3-482a-b449-0d65e4b1e1ea/mp4/BLT+LETTER+I+AND+J_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterIJ.pdf"
            }
        },
        "letterK"  : {
            "label" : "Day 10: Letter K",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/22961ccd-53a1-4e13-9f73-87df74ee6bfc/dash/BLT+SUSAN+LETTER+K.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/22961ccd-53a1-4e13-9f73-87df74ee6bfc/hls/BLT+SUSAN+LETTER+K.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/22961ccd-53a1-4e13-9f73-87df74ee6bfc/mp4/BLT+SUSAN+LETTER+K_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/2d9fc7e9-dd9e-417c-9d64-93734a32e149/dash/BLT+LETTER+K.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/2d9fc7e9-dd9e-417c-9d64-93734a32e149/hls/BLT+LETTER+K_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/2d9fc7e9-dd9e-417c-9d64-93734a32e149/mp4/BLT+LETTER+K_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterK.pdf"
            }
        },
        "letterLM"  : {
            "label" : "Day 11: Letters L and M",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c4365411-da40-4fd2-99a1-b6292e101fc8/dash/BLT+LETTERS+L+AND+M+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c4365411-da40-4fd2-99a1-b6292e101fc8/hls/BLT+LETTERS+L+AND+M+SUSAN_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c4365411-da40-4fd2-99a1-b6292e101fc8/mp4/BLT+LETTERS+L+AND+M+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e6022f51-be87-487c-a116-e2dbed9c87bc/dash/BLT+LETTER+L+AND+M.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e6022f51-be87-487c-a116-e2dbed9c87bc/hls/BLT+LETTER+L+AND+M_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/e6022f51-be87-487c-a116-e2dbed9c87bc/mp4/BLT+LETTER+L+AND+M_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterLM.pdf"
            }
        },
        "letterN"  : {
            "label" : "Day 12: Letter N",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89f2948c-5f98-4fb7-8609-1f8c071e4436/dash/Susan+Letter+N+orig.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89f2948c-5f98-4fb7-8609-1f8c071e4436/hls/Susan+Letter+N+orig.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89f2948c-5f98-4fb7-8609-1f8c071e4436/mp4/Susan+Letter+N+orig_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/10303a02-f821-42a4-8b51-683aff694fc0/dash/BLT+LETTER+N.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/10303a02-f821-42a4-8b51-683aff694fc0/hls/BLT+LETTER+N_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/10303a02-f821-42a4-8b51-683aff694fc0/mp4/BLT+LETTER+N_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterN.pdf"
            }
        },
        "letterOP"  : {
            "label" : "Day 13: Letters O and P",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f77dd09b-fbbc-434f-9550-3803bcce0b56/dash/LETTER+O+AND+P+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f77dd09b-fbbc-434f-9550-3803bcce0b56/hls/LETTER+O+AND+P+SUSAN.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/f77dd09b-fbbc-434f-9550-3803bcce0b56/mp4/LETTER+O+AND+P+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/34a313d4-eaf5-4e9d-83a9-e867635f3681/dash/BLT+LETTER+O+AND+P.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/34a313d4-eaf5-4e9d-83a9-e867635f3681/hls/BLT+LETTER+O+AND+P.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/34a313d4-eaf5-4e9d-83a9-e867635f3681/mp4/BLT+LETTER+O+AND+P_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterOP.pdf"
            }
        },
        "letterQR"  : {
            "label" : "Day 14: Letters Q and R",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/149311f3-e3a0-4a4f-8be7-9f219246c836/dash/BLT+LETTERS+Q+AND+R+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/149311f3-e3a0-4a4f-8be7-9f219246c836/hls/BLT+LETTERS+Q+AND+R+SUSAN_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/149311f3-e3a0-4a4f-8be7-9f219246c836/mp4/BLT+LETTERS+Q+AND+R+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c0758a3b-1ba8-4d30-88eb-e8e80a142ee5/dash/BLT+LETTER+Q+AND+R.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c0758a3b-1ba8-4d30-88eb-e8e80a142ee5/hls/BLT+LETTER+Q+AND+R.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/c0758a3b-1ba8-4d30-88eb-e8e80a142ee5/mp4/BLT+LETTER+Q+AND+R_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterQR.pdf"
            }
        },
        "letterS"  : {
            "label" : "Day 15: Letter S",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/83d47d2f-e214-481b-bfb5-ad160ed6174e/dash/BLT+LETTER+S+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/83d47d2f-e214-481b-bfb5-ad160ed6174e/hls/BLT+LETTER+S+SUSAN_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/83d47d2f-e214-481b-bfb5-ad160ed6174e/mp4/BLT+LETTER+S+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89b30ece-98ac-4422-bef3-b357336682bb/dash/MEILANI+BLT+LETTER+S.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89b30ece-98ac-4422-bef3-b357336682bb/hls/MEILANI+BLT+LETTER+S.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/89b30ece-98ac-4422-bef3-b357336682bb/mp4/MEILANI+BLT+LETTER+S_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterS.pdf"
            }
        },
        "letterT"  : {
            "label" : "Day 16: Letter T",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0f7673ce-8c64-4f24-b70a-a698304b4f0d/dash/Susan+Letter+T.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0f7673ce-8c64-4f24-b70a-a698304b4f0d/hls/Susan+Letter+T.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0f7673ce-8c64-4f24-b70a-a698304b4f0d/mp4/Susan+Letter+T_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/5527012d-3d42-4bbc-ab16-9ac85df28bf2/dash/BLT+LETTER+T.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/5527012d-3d42-4bbc-ab16-9ac85df28bf2/hls/BLT+LETTER+T_Ott_Hls_Ts_Avc_Aac_16x9_480x270p_15Hz_0.4Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/5527012d-3d42-4bbc-ab16-9ac85df28bf2/mp4/BLT+LETTER+T_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterT.pdf"
            }
        },
        "letterUV"  : {
            "label" : "Day 17: Letters U and V",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/3e369e05-343f-435d-91be-280ed6d24be3/dash/BLT+LETTERS+U+AND+V+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/3e369e05-343f-435d-91be-280ed6d24be3/hls/BLT+LETTERS+U+AND+V+SUSAN.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/3e369e05-343f-435d-91be-280ed6d24be3/mp4/BLT+LETTERS+U+AND+V+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/a08ae9ee-c93b-45a3-b2c2-6aff59352458/dash/BLT+LETTERS+U+AND+V.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/a08ae9ee-c93b-45a3-b2c2-6aff59352458/hls/BLT+LETTERS+U+AND+V_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/a08ae9ee-c93b-45a3-b2c2-6aff59352458/mp4/BLT+LETTERS+U+AND+V_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterUV.pdf"
            }
        },
        "letterW"  : {
            "label" : "Day 18: Letter W",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/1d736a00-3182-4e94-9d65-626938007e71/dash/Susan+Letter+W+-+edited.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/1d736a00-3182-4e94-9d65-626938007e71/hls/Susan+Letter+W+-+edited_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_3.5Mbps_qvbr.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/1d736a00-3182-4e94-9d65-626938007e71/mp4/Susan+Letter+W+-+edited_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/bd840436-2fce-4f20-914b-9c4c552c98ee/dash/Letter_W-edited.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/bd840436-2fce-4f20-914b-9c4c552c98ee/hls/Letter_W-edited.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/bd840436-2fce-4f20-914b-9c4c552c98ee/mp4/Letter_W-edited_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterW.pdf"
            }
        },
        "letterXY"  : {
            "label" : "Day 19: Letters X and Y",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0649c086-4319-4012-a1d7-c718cf91a8f8/dash/BLT+LETTERS+X+AND+Y+SUSAN.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0649c086-4319-4012-a1d7-c718cf91a8f8/hls/BLT+LETTERS+X+AND+Y+SUSAN.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/0649c086-4319-4012-a1d7-c718cf91a8f8/mp4/BLT+LETTERS+X+AND+Y+SUSAN_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/39dad458-0936-4940-ae6a-e77250f2c904/dash/BLT+LETTERS+X+AND+Y.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/39dad458-0936-4940-ae6a-e77250f2c904/hls/BLT+LETTERS+X+AND+Y.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/39dad458-0936-4940-ae6a-e77250f2c904/mp4/BLT+LETTERS+X+AND+Y_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterXY.pdf"
            }
        },
        "letterZ"  : {
            "label" : "Day 20: Letter Z",
            "preacademics" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/57a8f9fb-d70b-4c17-bea5-d852e777403d/dash/Susan+Letter+Z+-+edited.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/57a8f9fb-d70b-4c17-bea5-d852e777403d/hls/Susan+Letter+Z+-+edited.m3u8",
                "mp4" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/57a8f9fb-d70b-4c17-bea5-d852e777403d/mp4/Susan+Letter+Z+-+edited_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4"
            },
            "foundationalskills" : {
                "mpd"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/37b861ad-b79c-4ae8-bebe-aa1bb884aba8/dash/BLT+ABC+LETTER+Z.mpd",
                "m3u8" : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/37b861ad-b79c-4ae8-bebe-aa1bb884aba8/hls/BLT+ABC+LETTER+Z.m3u8",
                "mp4"  : "https://processvideofile-destination-m77ig3m5b8d2.s3.amazonaws.com/37b861ad-b79c-4ae8-bebe-aa1bb884aba8/mp4/BLT+ABC+LETTER+Z_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps_qvbr.mp4",
                "pdf"  : "https://blt-kindergarten-readiness-pdf.s3-us-west-1.amazonaws.com/LetterZ.pdf"
            }
        }
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    pdfClick = () => {
        alert("click");
    }

    render() {
      const { classes } = this.props;
      return (
          <div className='Login-component'>
            <AuthHeader gobackvisibility={"visible"} signoutvisibility={"hidden"} user={this.props.user} />
            <center>
            <br/>
            <Typography style={{ fontFamily:"LuloCleanOneBold", fontSize: "22pt", color: "#4d2c58" }}>
                {coursedesc[ this.props.match.params.index ]["items"][ this.props.match.params.coursesessionid ]["label"]}
            </Typography>
            <br/><br/>
            <Paper className={classes.paper}>
                <Grid width="90%">
                    <Typography style={{ fontFamily:"AmaticSC-Bold", fontSize: "35pt", color: "#4d2c58"}}>
                        Pre-Academics
                    </Typography>
                    <br/>
                    <video width={"80%"} controls controlsList="nodownload">
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["preacademics"]["mpd"]} type="application/dash+xml" />
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["preacademics"]["m3u8"]} type="application/x-mpegURL"/>
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["preacademics"]["mp4"]} type="video/mp4"/>
                    </video>
                    <br/>
                </Grid>
            </Paper>
            <br/><br/>
            <Paper className={classes.paper}>
                <Grid width="90%">
                    <Typography style={{ fontFamily:"AmaticSC-Bold", fontSize: "35pt", color: "#4d2c58"}}>
                        Foundational Skills: {coursedesc[ this.props.match.params.index ]["items"][ this.props.match.params.coursesessionid ]["activity_label"]}
                    </Typography>
                    <br/>
                    <video width={"80%"} controls controlsList="nodownload">
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["foundationalskills"]["mpd"]} type="application/dash+xml" />
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["foundationalskills"]["m3u8"]} type="application/x-mpegURL"/>
                        <source src={this.letterArray[ this.props.match.params.coursesessionid ]["foundationalskills"]["mp4"]} type="video/mp4"/>
                    </video>
                </Grid>
            </Paper>
            <br/><br/>
            <Paper className={classes.paper}>
                <Grid width="90%">
                    <Typography style={{ fontFamily:"AmaticSC-Bold", fontSize: "35pt", color: "#4d2c58"}}>
                        Activity Sheet
                    </Typography>
                    <br/>
                    <a href={this.letterArray[ this.props.match.params.coursesessionid ]["foundationalskills"]["pdf"]} target="_blank">
                        <Document file={this.letterArray[ this.props.match.params.coursesessionid ]["foundationalskills"]["pdf"]}>
                            <Page width={window.innerWidth * .6} pageNumber={1}/>
                        </Document>
                    </a>
                </Grid>             
            </Paper>
            <br/><br/>
            </center>
      </div>
      )
  }
}

export default withStyles(styles)(CourseSession);