/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:1ccfa32e-1e88-4a91-adf4-ee19b33214a6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_NPnAqeN9p",
    "aws_user_pools_web_client_id": "1q5li35vi6kt1nkr3s5mghoe8j",
    "oauth": {}
};


export default awsmobile;
