import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifyForgotPassword, AmplifyGreetings } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Link, Route, Switch } from "react-router-dom";
import awsconfig from './aws-exports';
import CourseSession from './components/CourseSession';
import CourseOverview from './components/CourseOverview';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

Amplify.configure(awsconfig);

const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
        <div>
          <Route exact path="/" user={user} render={routeProps => <CourseOverview {...routeProps} user={user}/>} />
          <Route exact path="/coursesession/:index/:coursesessionid" render={routeProps => <CourseSession {...routeProps} user={user}/> } /> 
        </div>
    ) : (
      <div class="Login-component">
        <center>
        <Typography style={{ fontSize: "22pt" }}>
        <img height={240}  src={process.env.PUBLIC_URL + "/KindergartenREADY_lockup_nobg_1200x630.png"}/>
        </Typography>
        <br/>
          <AmplifyAuthenticator>
            <AmplifySignUp style={{'--amplify-primary-color': '#f37658', '--border-color': '#FFFFFF', '--amplify-primary-shade': '#f37658'}} 
              usernameAlias="email"
              slot="sign-up"
              formFields={[
                { type: "email" },
                { type: "password" }
              ]}
            />
            <AmplifyForgotPassword headerText="Please enter your email address to reset your password." slot="forgot-password" usernameAlias="email"></AmplifyForgotPassword>
          </AmplifyAuthenticator>
        </center>
      </div>
  );
}

export default AuthStateApp;