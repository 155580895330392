import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { withStyles, ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifyForgotPassword, AmplifyGreetings } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Link, Route, Switch } from "react-router-dom";
import awsconfig from '../aws-exports';
import coursedesc from '../coursedescription.json';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#f37658',
        contrastText: "#ffffff"
      },
      secondary: {
        main: green[500],
      },
    },
    overrides: {
        MuiButton: {
          raisedPrimary: {
            color: 'white',
          },
        },
      }
  });

class AuthHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        console.log(coursedesc);
        const { classes } = this.props;
        return (
            <div className="App">
            <ThemeProvider theme={theme}>
            <table width="100%" >
              <tr width="100%">
                <td style={{padding: "30px"}} align="left">
                  <div id="goBackPlaceholder">
                    <div align={"left"} style={{marginLeft: "8%", marginRight: "18"}}>
                      <Button id="gobackbutton" href="/" size="large" variant="contained" color="primary" 
                        style={{visibility: this.props.gobackvisibility, borderRadius: 0, fontFamily: "LibreBaskerville", fontSize: 12 }}>
                          Go Back
                      </Button>
                    </div>
                  </div>
                </td>
                <td width="100em" height="10" align={"left"} padding="15em" margin="15em"><AmplifySignOut 
                  style={{'--amplify-primary-color': '#f37658', 
                  '--background-color': '#888888', 
                  '--border-color': '#FFFFFF', 
                  'visibility': this.props.signoutvisibility,
                  '--amplify-font-family': "LibreBaskerville"}} 
                  username={this.props.user.attributes.email} ></AmplifySignOut></td>
                <td width="10em"></td>
              </tr>
            </table>
            </ThemeProvider>
            </div>
      )
  }
}

export default AuthHeader;